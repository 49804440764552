.loading-background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    cursor: progress;
  }
  .loading-background .loading-bar {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
  }
  .loading-background .loading-bar .loading-circle-1,
  .loading-background .loading-bar .loading-circle-2 {
    content: " ";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #2CB180;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }
  .loading-background .loading-bar .loading-circle-2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  
  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }
  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }